<template>
  <div class="home">
    <img class="logoimg" alt="logo" src="../assets/logo.png">
    <h1>面包记账 支持</h1>
    <h3 style="color: #F4F4EC; padding-bottom: 100px;" >Keep Bread Support</h3>
    <div class="card-container">
      <Card  title="这是什么" description="面包记账App — 让预算控制触手可及为专注于每月固定收入的学生和上班族量身打造，面包记账App让您更轻松地管理您的资金流动。设定每月总资金和每日预算，掌握财务状况从未如此简单。
                                          核心特点：
                                          预算设定：只需一次设置，每日都能根据您的需求进行预算跟踪。
                                          日常账单查阅：清晰展示每日的支出与收入，让您对财务有更直观的了解。
                                          项目计划：您可以建立自己的存钱计划与存款目标，助您达成财务目标。
                                          数据分析：提供每月和每日的预算剩余概览，帮助您更好地规划与调整。
                                          丰富图表：直观地显示您的资金流动，一目了然。
                                          面包记账App，为您提供更智能、更直观的预算管理体验。下载今天，让您的每一分钱都算数！"
      link="https://keepbread.dmxx.ltd/" linkText="我要使用"/>
    </div>
    <SearchBar/>
    <div class="footer">
      <a class="footerText">Copyright @ 2023 Damien</a>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue'
import SearchBar from '@/components/SearchBar.vue'

export default {
  name: 'HomeView',
  components: {
    Card,
    SearchBar
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
}
.logoimg {
  width: 200px;
  height: 200px;
  border-radius: 50px;
}
.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.footerText{
  text-decoration: none;
  color: black;
  padding-top: 100px;
}
</style>